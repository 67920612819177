<template>
  <div class="sales-service" v-loading="loading">
    <div class="s-title">售后服务</div>
    <div class="s-search clearfix">
      <li>
        <span>服务类型：</span>
        <el-select
          v-model="searchItem.refundTypeValue"
          placeholder="请选择"
          size="small"
          clearable
          @change="afterSalesChange()"
        >
          <el-option
            v-for="item in refundTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </li>
      <li class="l-state">
        <span>处理状态：</span>
        <el-select
          v-model="searchItem.refundStateValue"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option
            v-for="item in refundStateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </li>
      <li>
        <el-button type="primary" size="small" @click="getList(1)"
          >搜索</el-button
        >
      </li>
    </div>
    <!--商品展示Table表格 -->
    <table>
      <tr class="t-title">
        <th>商品</th>
        <th>退款金额</th>
        <th>申请时间</th>
        <th>服务类型</th>
        <th>处理状态</th>
        <th>交易操作</th>
      </tr>
      <tbody v-for="(item, index) in list" :key="index">
        <tr class="t-hd">
          <td colspan="6">
            <li class="h-item">
              订单编号:&nbsp;<span>{{ item.Order.order_no }}</span>
            </li>
            <li class="h-item">
              退款编号:&nbsp;<span>{{ item.order_no }}</span>
            </li>
            <li class="h-item h-itemto" @click="ToStore(item.store_id)">
              {{ item.store_name }}
            </li>
          </td>
        </tr>
        <tr class="t-goods">
          <td class="g-content" @click="Togoods(item.Goods.goods_id)">
            <div class="c-img">
              <img :src="item.Goods.img" />
            </div>
            <div class="c-name">
              <div class="n-title">{{ item.Goods.goods_name }}</div>
              <div class="n-attr"></div>
            </div>
          </td>
          <td align="center" class="g-color">￥{{ item.refundsum }}</td>
          <td align="center">{{ item.create_time }}</td>
          <td align="center" class="g-color">
            {{ item.type == 1 ? "仅退款" : "退货退款" }}
          </td>
          <td align="center" class="g-success">
            <span v-if="item.type == 1">{{ item.status | refundstatus }}</span>
            <span v-if="item.type == 2">{{ item.status | bothstatus }}</span>
          </td>
          <td
            align="center"
            class="g-color g-pointer"
            @click="goToRefundDetails(item.id)"
          >
            服务详情
          </td>
        </tr>
      </tbody>
    </table>
    <div class="o-footer">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="count"
        :page-size="limit"
        @current-change="getList(page)"
        :current-page.sync="page"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { AfterSaleList } from '@/api/order'
export default {
  name: "afterSalesService",
  data() {
    return {
      limit:10,
      count:0,
      page:0,
      list:[],
      loading:false,
      searchItem: {
        refundTypeValue: "1",
        refundStateValue: ""
      },
      //退款类型
      refundTypeOptions: [
      {
        value: '1',
        label: '仅退款'
      },
      {
        value: '2',
        label: '退货退款'
      }],
      //退款状态
      refundStateOptions: [
        {value: '1',label: '等待商家同意'},
        {value: '2',label: '等待系统退款'},
        {value: '3',label: '已退款'},
        {value: '7',label: '审核不通过'}
      ],
    }
  },
   filters: {
      serviceType(val) {
        if (val == 1) return "仅退款"
        if (val == 2) return "退货退款"
      },
      refundstatus(val) { // 仅退款处理状态
        if (val == 1) return '等待商家同意';
        if (val == 2) return '等待系统退款';
        if (val == 3) return '已完成';
        if (val == 7) return '审核不通过';
      },
      bothstatus(val) {  // 退货退款处理状态
        if (val == 1) return '等待买家发货';
        if (val == 2) return '等待商家收货';
        if (val == 3) return '等待商家同意';
        if (val == 4) return '等待系统退款';
        if (val == 5) return '已退款';
        if (val == 7) return '审核不通过';
      },
    },
  methods: {
    async getList(page){
      this.loading = true;
      this.searchItem.page  = page;
      this.searchItem.limit = this.limit;
      const {data: res} = await AfterSaleList(this.searchItem);
      if (res.message == 'ok') {
        this.list  = res.result.list;
        this.count = res.result.count;
      }
      this.loading = false;
    },
    goToRefundDetails(id){
      this.$router.push('/user/refund/details?id='+id)
    },
    Togoods(id){ // 进入商品详情页
      let routeUrl = this.$router.resolve({
        path: "/store/goods",
        query: { id: id }
      });
      window.open(routeUrl.href, '_blank');
    },
    ToStore(id){ // 进入门店首页
      let routeUrl = this.$router.resolve({
        path: "/store/home",
        query: { id: id }
      });
      window.open(routeUrl.href, '_blank');
    },
    afterSalesChange(){
      if(this.searchItem.refundTypeValue==1){
        this.refundStateOptions = [
        {value: '1',label: '等待商家同意'},
        {value: '2',label: '等待系统退款'},
        {value: '3',label: '已退款'},
        {value: '7',label: '审核不通过'}
        ];
      }
      if(this.searchItem.refundTypeValue==2){
        this.refundStateOptions = [
          {value: '1',label: '等待买家发货'},
          {value: '2',label: '等待仓库收货'},
          {value: '3',label: '等待商家同意'},
          {value: '4',label: '等待系统退款'},
          {value: '5',label: '已退款'},
          {value: '7',label: '审核不通过'}
        ];
      }
      this.searchItem.refundStateValue = "";
    },
  },
  created() {
    this.getList(1)
  }
}
</script>

<style lang="less" scoped>
.o-footer {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.sales-service {
  padding: 20px 40px;

  .s-title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #282828;
    border-bottom: 1px solid #ddd;
  }
  .s-search {
    margin: 20px 0px;
    display: flex;
    justify-content: flex-end;
    .l-state {
      margin-left: 10px;
    }
  }
  table {
    .t-title {
      height: 40px;
      background: #fafafa;
      border: 1px solid #ddd;
      th {
        font-weight: 500;
      }
    }
    .t-hd {
      border-left: 1px solid #eaf8ff;
      border-right: px solid #eaf8ff;
      background: #eaf8ff;
      height: 40px;
      .h-item {
        float: left;
        margin-left: 20px;
      }
      .h-itemto {
        cursor: pointer;
      }
    }
    .t-goods {
      border: 1px solid #ddd;
      .g-content {
        padding: 20px;
        display: flex;
        cursor: pointer;
        .c-img {
          width: 80px;
          height: 80px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .c-name {
          .n-title {
            width: 300px;
            color: #666;
          }
          .n-attr {
            margin-top: 5px;
          }
        }
      }
      .g-color {
        color: #e1251b;
      }
      .g-success {
        width: 150px;
        color: #4ac375;
      }
      .g-pointer {
        cursor: pointer;
      }
    }
  }
}
</style>